<script setup lang="ts">
import { useDark, useWindowSize, useThrottleFn } from '@vueuse/core'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import useAppStore from './stores/modules/app'
import useSettingStore from './stores/modules/setting'
import { ScreenEnum } from './enums/appEnums'
const appStore = useAppStore()
const settingStore = useSettingStore()
const elConfig = {
    zIndex: 3000,
    locale: zhCn
}
const isDark = useDark()
onMounted(async () => {
    //设置主题色
    settingStore.setTheme(isDark.value)
    // 获取配置
    const data: any = await appStore.getConfig()
    // 设置网站logo
    let favicon: HTMLLinkElement = document.querySelector('link[rel="icon"]')!
    if (favicon) {
        favicon.href = data.web_favicon
        return
    }
    favicon = document.createElement('link')
    favicon.rel = 'icon'
    favicon.href = data.web_favicon
    document.head.appendChild(favicon)
})

const { width } = useWindowSize()
watch(
    width,
    useThrottleFn((value) => {
        if (value > ScreenEnum.SM) {
            appStore.setMobile(false)
            appStore.toggleCollapsed(false)
        } else {
            appStore.setMobile(true)
            appStore.toggleCollapsed(true)
        }
        if (value < ScreenEnum.MD) {
            appStore.toggleCollapsed(true)
        }
    }),
    {
        immediate: true
    }
)
</script>

<template>
    <el-config-provider :locale="elConfig.locale" :z-index="elConfig.zIndex">
        <router-view />
    </el-config-provider>
</template>

<style lang="scss">
@import 'https://at.alicdn.com/t/c/font_4302937_2sze5c3aliw.css';
@import 'https://at.alicdn.com/t/c/font_4681619_4fpzkrjmdg.css';


body {
    font-family: PingFang SC, Arial, Microsoft YaHei, sans-serif;
}

.el-form--inline .el-form-item {
    margin-right: 16px !important;
}

.cell {
    padding-left: 8px !important;
    padding-right: 8px !important;
}

.searchCard {
    .el-form-item {
        width: 295px;
    }
}
</style>
