import { createApp } from 'vue'
import App from './App.vue'
import install from './install'
import './permission'
import './styles/index.scss'
import 'virtual:svg-icons-register'

// 完整导入 UI 组件库
import VxeUI from 'vxe-pc-ui'
import 'vxe-pc-ui/lib/style.css'
// ...

// 完整导入 表格库
import VxeUITable from 'vxe-table'
import 'vxe-table/lib/style.css'
// ...

const app = createApp(App)
app.use(VxeUI)
app.use(VxeUITable)
// 富文本编辑器
import EditorTinymce from '@tinymce/tinymce-vue'
app.component('EditorTinymce', EditorTinymce)

// 图片预览插件
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
app.use(VueViewer)

app.use(install)
app.mount('#app')
